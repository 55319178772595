import { isCypress } from "../api.utils";
import { getCookie, isHttps, setCookie } from "./index";

export type UtmObject = {
  campaign: string;
  content: string;
  medium: string;
  source: string;
  term: string;
};

export const cookieDomain = isCypress
  ? "localhost"
  : process.env.NEXT_PUBLIC_PUSHPRESS_COOKIE_WEBSITE;

export function getUtmFromUrl(url: string): Partial<UtmObject> {
  try {
    const queryString = url.includes('?') ? url.split('?')[1] : url;
    const urlParams = new URLSearchParams(queryString);
    const params: Partial<UtmObject> = {};
    
    if (urlParams.has('utm_campaign')) {
      params.campaign = decodeURIComponent(urlParams.get('utm_campaign') || '');
    }
    if (urlParams.has('utm_content')) {
      params.content = decodeURIComponent(urlParams.get('utm_content') || '');
    }
    if (urlParams.has('utm_medium')) {
      params.medium = decodeURIComponent(urlParams.get('utm_medium') || '');
    }
    if (urlParams.has('utm_source')) {
      params.source = decodeURIComponent(urlParams.get('utm_source') || '');
    }
    if (urlParams.has('utm_term')) {
      params.term = decodeURIComponent(urlParams.get('utm_term') || '');
    }
    
    return params;
  } catch {
    return {};
  }
}

export function setUtmCookies(utmParams: Partial<UtmObject>) {
  const thirtyDays = 30 * 24 * 60 * 60;
  const cookieOptions = {
    domain: cookieDomain,
    path: '/',
    secure: !isCypress && isHttps(),
    sameSite: 'Lax' as const,
    maxAge: thirtyDays
  };

  if (utmParams.campaign) {
    setCookie('utm_campaign', utmParams.campaign, cookieOptions);
  }
  if (utmParams.content) {
    setCookie('utm_content', utmParams.content, cookieOptions);
  }
  if (utmParams.medium) {
    setCookie('utm_medium', utmParams.medium, cookieOptions);
  }
  if (utmParams.source) {
    setCookie('utm_source', utmParams.source, cookieOptions);
  }
  if (utmParams.term) {
    setCookie('utm_term', utmParams.term, cookieOptions);
  }
}

export function getUtmFromCookies(): UtmObject {
  return {
    campaign: getCookie('utm_campaign'),
    content: getCookie('utm_content'),
    medium: getCookie('utm_medium'),
    source: getCookie('utm_source'),
    term: getCookie('utm_term'),
  };
}

// Utility function to get UTM source mapping
const UTM_SOURCE_MAPPING: { [key: string]: string } = {
  fb: "SOCIAL_MEDIA",
  ig: "SOCIAL_MEDIA",
  facebook: "SOCIAL_MEDIA",
  instagram: "SOCIAL_MEDIA",
  google: "ORGANIC_SEARCH",
};

// Utility function to get Campaign Source
const getCampaignSource = (utm: UtmObject): string =>
  utm?.campaign?.length ? "PAID" : "";

// This function matches the current marketing implementation to get the original source name
export function getOriginalSource(): string {
  const utm = getUtmFromCookies();
  const baseSource: string =
    UTM_SOURCE_MAPPING[utm?.source] || "DIRECT_TRAFFIC";
  const campaignSource: string = getCampaignSource(utm);

  return `${campaignSource}_${baseSource}`.replace(/^_+/, "");
} 
